import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "3:00-4:00",
    Long: 2.5,
    Short: -1.5,
    number: 3,
  },
  {
    name: "4:00-5:00",
    Long: -3,
    Short: -1,
    number: 0.5,
  },
  {
    name: "5:00-6:00",
    Long: -0.5,
    Short: -1.5,
    number: 1,
  },
  {
    name: "6:00-7:00",
    Long: 2,
    Short: -2.5,
    number: 0,
  },
  {
    name: "7:00-8:00",
    Long: 2,
    Short: -1,
    number: -1,
  },
  {
    name: "8:00-9:00",
    Long: 2,
    Short: -3,
    number: -2,
  },
  {
    name: "9:00-10:00",
    Long: 349,
    Short: 1,
    number: -3,
  },
];

export default function App() {
  return (
    <div className="2xl:w-auto xl:w-auto lg:w-auto md:w-auto sm:w-[21rem] max-sm:w-[21rem]">
      <ResponsiveContainer width="100%" height={250}>
        <BarChart
          width={550}
          height={250}
          data={data}
          stackOffset="sign"
          margin={{
            top: 5,
            right: 30,
            left: -30,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="1 1" />
          <XAxis dataKey="name" />
          <YAxis dataKey="number" />
          <Tooltip />
          <Legend />
          <ReferenceLine y={0} stroke="#000" />
          <Bar dataKey="Long" fill="#304986" stackId="stack" />
          <Bar dataKey="Short" fill="#01593d" stackId="stack" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
